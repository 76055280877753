/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import { graphql, StaticQuery } from "gatsby";

// components
import categoryIcon from "./assets/categoryicon.svg";
import arrow from "./assets/arrow.svg";
import arrowGray from "./assets/arrow2.svg";
import RenderCardsPartnersModal from "../ResourcesCardPartnersModal/resourcesCardPartnersModal";
import { ContentWrapper } from "../../styles/globals/grid";

import {
  Container,
  ResourceContainer,
  SubCategoriesWrapper,
  CategoriesTitle,
  CategoriesList,
  CategoryItem,
  ResourcesWrapper,
  GeneralContainer,
  ResourcesList,
  ItemContainer,
  CategoryContainer,
  CategoryIconContainer,
  CategoryIcon,
  Line,
  CategoriesWrapper,
  SideBar,
  SubItemContainer,
  SubCategoryItem,
  SubCategoriesList,
  SubCategoriesListMobile,
  Arrow,
} from "./PartnersIndex.styles";

import RenderCardsPartners from "../ResourcesCardPartners/resourcesCardPartners";

const PartnersIndexComponent = (data) => {
  const [selectedCategory, setSelectedCategory] = useState("Facilities");
  const [subCategoriesActive, setsubCategoriesActive] = useState(true);
  const [openModalViaTitle, setOpenModalViaTitle] = useState("");

  const changeSelectedCategory = (category) => {
    setSelectedCategory(category);
    setsubCategoriesActive(category === "Facilities");
  };

  const categories = data?.data?.cms?.partnersCategories?.data || [];
  const articles = data?.data?.cms?.partnersArticles?.data || [];

  const categoriesFiltered = [];
  const subCategoriesFiltered = [];
  categories.forEach((category) => {
    const categoryLowerCase = category.attributes.Category_Title.toLowerCase();
    if (
      categoryLowerCase.includes("facilities") &&
      categoryLowerCase.trim() !== "facilities"
    ) {
      subCategoriesFiltered.push({
        category,
      });
    } else {
      categoriesFiltered.push({
        category,
      });
    }
  });

  const ArticlesToRender = [];
  categoriesFiltered.forEach((category) => {
    const categoryArticles = articles.filter((Article) => {
      const articleCategoriesTitles = Article.attributes.Article_Category.data.map(
        (category) => {
          return category.attributes.Category_Title;
        }
      );
      return articleCategoriesTitles.includes(
        category.category.attributes.Category_Title
      );
    });
    ArticlesToRender.push({
      category: category.category.attributes.Category_Title,
      articles: categoryArticles,
    });
  });

  const subArticlesToRender = [];
  subCategoriesFiltered.forEach((category) => {
    const categoryArticles = articles.filter((Article) => {
      const articleCategoriesTitles = Article.attributes.Article_Category.data.map(
        (category) => {
          return category.attributes.Category_Title;
        }
      );
      return articleCategoriesTitles.includes(
        category.category.attributes.Category_Title
      );
    });
    subArticlesToRender.push({
      category: category.category.attributes.Category_Title,
      articles: categoryArticles,
    });
  });

  const allCategories = [...ArticlesToRender, ...subArticlesToRender];

  const renderCategories = () => {
    const items = ArticlesToRender;

    return items.map((group) => {
      let isActive = selectedCategory === group.category;
      if (
        selectedCategory.includes("Facilities") &&
        group.category === "Facilities"
      ) {
        isActive = true;
      }
      if (group?.articles?.length > 0 || group.all) {
        return (
          <div>
            <ItemContainer
              isActive={isActive}
              key={group.category}
              onClick={() => changeSelectedCategory(group.category)}
            >
              <CategoryItem isActive={isActive}>
                {group.category}{" "}
                {group.category === "Facilities" && (
                  <Arrow
                    src={subCategoriesActive ? arrow : arrowGray}
                    onClick={() => setsubCategoriesActive(!subCategoriesActive)}
                  />
                )}
              </CategoryItem>
            </ItemContainer>
            {selectedCategory.includes("Facilities") &&
              group.category === "Facilities" &&
              subCategoriesActive && (
                <SubCategoriesListMobile>
                  {renderSubCategories()}
                </SubCategoriesListMobile>
              )}
          </div>
        );
      }
    });
  };

  const renderSubCategories = () => {
    const items = subArticlesToRender;

    return items.map((group) => {
      const isActive = selectedCategory === group.category;
      if (group?.articles?.length > 0 || group.all) {
        const firstSpaceIndex = group.category.indexOf(" ");
        const newSubCateogry = group.category.substring(firstSpaceIndex + 1);
        return (
          <SubItemContainer
            isActive={isActive}
            key={group.category}
            onClick={() => setSelectedCategory(group.category)}
          >
            <SubCategoryItem isActive={isActive}>
              {newSubCateogry}
            </SubCategoryItem>
          </SubItemContainer>
        );
      }
    });
  };

  const handleOpenModal = (title) => {
    setOpenModalViaTitle(title);
  };

  useEffect(() => {
    if (openModalViaTitle) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openModalViaTitle]);

  return (
    <Container>
      <ContentWrapper>
        <CategoriesWrapper>
          <CategoryIconContainer />
          <CategoriesList>{renderCategories()}</CategoriesList>
        </CategoriesWrapper>
        <GeneralContainer>
          {selectedCategory.toLocaleLowerCase().includes("facilities") && (
            <SideBar>
              <ResourceContainer>
                {/* Categories List */}
                <SubCategoriesWrapper>
                  <CategoryIconContainer>
                    <CategoryIcon src={categoryIcon} />
                    <CategoriesTitle>Categories</CategoriesTitle>
                  </CategoryIconContainer>
                  <Line />
                  <SubCategoriesList>{renderSubCategories()}</SubCategoriesList>
                </SubCategoriesWrapper>
                {/* Article Cards List */}
              </ResourceContainer>
            </SideBar>
          )}

          <ResourcesWrapper
            isSubCategory={selectedCategory
              .toLocaleLowerCase()
              .includes("facilities")}
          >
            <>
              {allCategories.map((group) => {
                if (
                  selectedCategory === group.category ||
                  (selectedCategory === "All Media Library" &&
                    group.articles.length)
                )
                  return (
                    <CategoryContainer>
                      {selectedCategory !== "All Media Library" && (
                        <ResourcesList showAll={false}>
                          {group.articles.map((article) => {
                            return (
                              <>
                                <RenderCardsPartners
                                  resource={article}
                                  isSubCategory={selectedCategory
                                    .toLocaleLowerCase()
                                    .includes("facilities")}
                                  handleOpenModal={handleOpenModal}
                                />
                                {openModalViaTitle ===
                                  article.attributes.Article_Title && (
                                  <RenderCardsPartnersModal
                                    data={article}
                                    handleClose={() => setOpenModalViaTitle("")}
                                  />
                                )}
                              </>
                            );
                          })}
                        </ResourcesList>
                      )}
                    </CategoryContainer>
                  );
              })}
            </>
          </ResourcesWrapper>
        </GeneralContainer>
      </ContentWrapper>
    </Container>
  );
};

const PartnersIndex = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          cms {
            partnersArticles(pagination: { limit: -1 }) {
              data {
                attributes {
                  Article_Category {
                    data {
                      attributes {
                        Category_Title
                      }
                    }
                  }
                  Article_Description
                  Article_Title
                  Article_CTA_Link
                  Article_Adress {
                    Article_Adress
                  }
                  Article_Phone {
                    Article_Phone
                  }
                  Article_Images {
                    Image {
                      data {
                        attributes {
                          alternativeText
                          url
                          mime
                          urlSharp {
                            childImageSharp {
                              gatsbyImageData
                            }
                          }
                        }
                      }
                    }
                  }
                  Article_Main_Image {
                    ...Media
                  }
                  Article_County
                  Article_Types_Of_Care
                }
              }
            }
          }
          cms {
            partnersCategories(pagination: { limit: -1 }) {
              data {
                attributes {
                  Category_Title
                }
              }
            }
          }
        }
      `}
      render={(data) => <PartnersIndexComponent data={data} />}
    />
  );
};

export default PartnersIndex;
